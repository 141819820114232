
/**
 * Show header indicator when the system is in maintenance mode for the admin
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  name: "SystemMaintenanceIndicator",
  computed: {
    ...mapGetters("Theme", {
      isInMaintenance: "GET_MAINTENANCE_MODE"
    })
  }
})
export default class SystemMaintenanceIndicator extends Vue {
  readonly isInMaintenance!: boolean;
  /*----------  Props  ----------*/
}
