
/**
 *Default container for the view and set the default nav
 depending in the user role.
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import {Component} from "vue-property-decorator";
// import router from "@/router";
import {ActionMethod, mapActions, mapGetters} from "vuex";
//@ts-ignore
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  AsideToggler,
  Footer as TheFooter,
  Breadcrumb
  //@ts-ignore
} from "@coreui/vue";

import DefaultHeaderDropdownAccnt from "@/containers/DefaultHeaderDropdownAccnt.vue";
import SystemMaintenanceIndicator from "@/components/shared/SystemInMaintenanceModeIndicator.vue";
import {SystemSettings} from "@/services/SystemSettings";

interface User {
  language: string;
  firstName: string;
  username: string;
  lastName: string;
}

@Component({
  name: "DefaultContainer",
  components: {
    AsideToggler,
    SidebarHeader,
    AppHeader,
    AppSidebar,
    TheFooter,
    Breadcrumb,
    DefaultHeaderDropdownAccnt,
    SystemMaintenanceIndicator,
    SidebarToggler,
    SidebarNav,
    SidebarMinimizer
  },
  computed: {
    ...mapGetters(["isUserAdmin", "isUserAgency", "isUserProjectManager", "isConstructionManager", "getCurrentUser"]),
    ...mapGetters("Theme", {
      CURRENT_THEME: "GET_CURRENT_THEME",
      isInMaintenance: "GET_MAINTENANCE_MODE",
      isProductiveSystem: "GET_PRODUCTIVE_SYSTEM"
    })
  },
  methods: {
    ...mapActions(["checkUserAdmin", "getUserInfo", "resetUser"]),
    ...mapActions("Theme", {
      UPDATE_SYSTEM_STATE: "UPDATE_SYSTEM_STATE"
    })
  }
})
export default class DefaultContainer extends Vue {
  /*----------  Vuex  ----------*/
  readonly isUserAdmin!: boolean;
  readonly isUserAgency!: boolean;
  readonly isUserProjectManager!: boolean;
  readonly isInMaintenance!: boolean;
  readonly isProductiveSystem!: any;

  readonly getCurrentUser!: User;
  readonly CURRENT_THEME!: string;

  private checkUserAdmin!: Function;
  private getUserInfo!: Function;
  private UPDATE_SYSTEM_STATE!: ActionMethod;

  /*----------  Local data   ----------*/
  // nav: Array<object> = [];
  // role: Array<Object> = this.$keycloak.realmAccess?.roles || [];
  VERSION = process.env.VUE_APP_VERSION;
  checkMaintenanceModeIntervalId: number | null = null;

  /*----------  Vue lifecycles  ----------*/
  created() {
    this.checkUserRole();
    this.getUserInfo(this.$keycloak.tokenParsed?.sub).then(() => {
      this.setUserLang();

      let bodyEl: HTMLBodyElement | null = document.querySelector("body");
      bodyEl?.setAttribute("data-theme", this.CURRENT_THEME);
    });
    this.checkMaintenanceMode();
  }

  /*----------  Computed  ----------*/
  get name() {
    return this.$route.path;
  }

  get list() {
    let routeItems = this.$route.matched.filter((route) => route);
    return routeItems.map((item) => {
      if (item.name) {
        //@ts-ignore
        item.meta.label = this.$t(item.name);
      } else if (!item.name) {
        // Check if view is in the current component
        if (item.path === this.name) {
          item.meta.label = "";
        } else {
          // If the view is a child of the current path we ned to add a label to it
          item.meta.label = this.$t(`router.${item.path.replace("/", "")}`);
        }
      }
      return item;
    });
  }

  get logoImagePath() {
    switch (this.CURRENT_THEME) {
      case "dark":
        return "/img/brand/logo-ibomade-white.png";

      default:
        return "/img/brand/logo-ibomade.png";
    }
  }

  /*----------  Methods  ----------*/
  async checkUserRole() {
    await this.checkUserAdmin();
  }

  setUserLang() {
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", this.getCurrentUser.language);
    } else {
      localStorage.setItem("lang", this.getCurrentUser.language);
    }
    this.$i18n.locale = this.getCurrentUser.language;
  }

  HandleOpenLinks(e: any) {
    if (e.target.textContent.trim() !== this.$t("sideBarDefaultOpen.project")) return;
    if (this.$route.path === "/projects") return;
    this.$router.push({path: "/projects"});
  }

  async checkMaintenanceMode() {
    let CHECK_SYSTEM_EVERY_MINUTE = 1000 * 60;
    this.checkMaintenanceModeIntervalId = setInterval(async () => {
      console.log("### CHECK SYSTEM STATUS #####");
      const maintenanceMode = await SystemSettings.getSystemConfig();
      const isInMaintenanceMode = maintenanceMode.status === 202 && maintenanceMode.data.maintenanceMode;
      this.UPDATE_SYSTEM_STATE(isInMaintenanceMode);
    }, CHECK_SYSTEM_EVERY_MINUTE);
  }

  /*----------  Remove the interval Before destroy the component   ----------*/

  beforeDestroy() {
    if (this.checkMaintenanceModeIntervalId) {
      clearInterval(this.checkMaintenanceModeIntervalId);
    }
  }
}
